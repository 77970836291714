


































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import LayoutCompact from '@/layouts/LayoutCompact.vue'
import CreditCardVerificationService from '@/services/CreditCardVerificationService'
import Constants from '../constants'

@Component({
  components: {
    LayoutCompact
  }
})
export default class ChangeCreditCard extends Vue {
  public optionMonths: any = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12'
  ]
  public optionYears: any = []
  public status: string = 'error'
  public card_number: any = ''
  public expire_year: any = ''
  public expire_month: any = ''
  public cvc: any = ''
  public firstName: any = ''
  public lastName: any = ''
  public msg: any = ''
  public isDisableInfoCard: boolean = true

  private paygentTokenStr: string = ''
  private fingerprint: string = ''

  @Watch('card_number')
  changeCvcCreditCard() {
    this.isDisableInfoCard = false
  }

  async created() {
    let year: any = parseInt(
      new Date()
        .getFullYear()
        .toString()
        .substr(2, 2)
    )
    for (let i = 0; i < 10; i++) {
      this.optionYears.push(year + i)
    }
    await CreditCardVerificationService.getCreditCard().then(
      (response: any) => {
        if (response.data.items.length !== 0) {
          let items = response.data.items.data
          if (items.card_number !== '') {
            this.card_number = 'XXXX-XXXX-XXXX-' + items.card_number
          }
          if (items.expire_month !== '') {
            this.expire_month = items.expire_month
          }
          if (items.expire_year !== '') {
            this.expire_year = items.expire_year
          }
          if (items.user_first_name !== '') {
            this.firstName = items.user_first_name
          }
          if (items.user_last_name !== '') {
            this.lastName = items.user_last_name
          }
        }
      },
      (error: any) => {
        if (error.response.status === 429) {
          localStorage.clear()
          this.$bvModal.show(`too-many-request`)
        }
      }
    )
    this.isDisableInfoCard = true
  }

  public changeCreditCard() {
    if (!this.isDisableInfoCard && this.cvc) {
      let script = document.createElement('script')
      let token_url: any = Constants.token_url
      script.setAttribute('src', token_url)
      document.head.appendChild(script)
      script.onload = () => {
        // @ts-ignore
        // eslint-disable-next-line no-undef
        let paygentToken = new PaygentToken()
        // @ts-ignore
        paygentToken.createToken(
          Constants.merchant_id, // merchant_id
          Constants.payment_token, // token
          {
            card_number: this.card_number, // 卡号
            expire_year: this.expire_year, // 有效年 19
            expire_month: this.expire_month, // 有效月 09
            cvc: this.cvc, // 安全码
            name: this.firstName + this.lastName // 用户名
          },
          this.execPurchase
        )
      }
    } else {
      this.msg = '「セキュリティコード」を正しく入力してください。'
      this.$bvModal.show('paygent-message')
    }
  }

  private execPurchase(response: any) {
    switch (response.result) {
      case '0000':
        this.paygentTokenStr = response.tokenizedCardObject.token
        this.fingerprint = response.tokenizedCardObject.fingerprint
        CreditCardVerificationService.changeCreditCard(
          this.paygentTokenStr,
          this.fingerprint,
          this.card_number,
          this.expire_year,
          this.expire_month,
          this.firstName,
          this.lastName
        ).then(
          (response: any) => {
            if (response) {
              this.$bvModal.show('change-credit-card-success')
            }
          },
          (error: any) => {
            if (error.response.status === 429) {
              localStorage.clear()
              this.$bvModal.show(`too-many-request`)
            }
            if (error.response.status === 500) {
              if (
                error.response.data.message[0] ===
                'CAN_NOT_CHANGE_CARD_INFORMATION'
              ) {
                this.$bvModal.show('cannot-change-credit-card')
              }
            }
          }
        )
        break
      case '1100':
        this.msg = 'マーチャントID - 必須エラー'
        break
      case '1200':
        this.msg = 'トークン生成公開鍵 - 必須エラー'
        break
      case '1201':
        this.msg = 'トークン生成公開鍵 - 不正エラー'
        break
      case '1300':
        this.msg =
          '「カード番号」を半角数字のみで入力してください。※「 - 」ハイフンは不要'
        break
      case '1301':
        this.msg =
          '「カード番号」を半角数字のみで入力してください。※「 - 」ハイフンは不要'
        break
      case '1400':
        this.msg = '有効期限(年) - 必須チェックエラー'
        break
      case '1401':
        this.msg = '有効期限(年) - 書式チェックエラー'
        break
      case '1500':
        this.msg = '有効期限(月) - 必須チェックエラー'
        break
      case '1501':
        this.msg = '有効期限(月) - 書式チェックエラー'
        break
      case '1502':
        this.msg = '有効期限(年月)が不正です。'
        break
      case '1600':
        this.msg = '「セキュリティーコード」を半角数字で入力してください。'
        break
      case '1601':
        this.msg =
          'セキュリティコード - 必須エラー（セキュリティコードトークンの場合）'
        break
      case '1700':
        this.msg = 'カード名義 - 書式チェックエラー'
        break
      case '7000':
        this.msg = '非対応のブラウザです。'
        break
      case '7001':
        this.msg = 'ペイジェントとの通信に失敗しました。'
        break
      case '8000':
        this.msg = 'システムメンテナンス中です。'
        break
      case '9000':
        this.msg = 'ペイジェント決済システム内部エラー'
        break
    }
    if (response.result != '0000') {
      this.$bvModal.show('paygent-message')
    }
  }

  public changeCreditCardSuccess() {
    this.$router.push({ name: 'credit-card-verification' })
  }

  public closeModal() {
    this.$bvModal.hide('paygent-message')
  }
}
